import { SyncStoreProgressChart } from 'components/charts/SyncStoreProgressChart/SyncStoreProgressChart';
import React, { useEffect, useState } from 'react';
import { usersApi } from 'store/api/usersApi';
import { useGetStoreSyncProgressSubscription } from 'hooks/useGetStoreSyncProgressSubscription';
import PaymentButton from '../PaymentPage/PaymentButton';
import { CatalogAndMarketplaceEvolution } from 'components/charts/CatalogAndMarketplaceEvolution/CatalogAndMarketplaceEvolution';
import { SalesEvolution } from 'components/charts/SalesEvolution/SalesEvolution';
import { ItemsInfractionsChart } from 'components/charts/ItemsInfractions/ItemsInfractions';

const DashboardCommon = ({ userInfoData }: any) => {
  const [createMLRedirectUrl] = usersApi.useCreateMLRedirectUrlMutation();

  const authorizeMercadoLibre = async () => {
    const { url } = await createMLRedirectUrl(userInfoData).unwrap();
    window.location.href = url;
  }

  return (
    <div className='p-10 grid grid-cols-2 gap-2'>
      <div className='border border-gray-100 p-6 py-14 bg-gradient-to-b from-primary-200 to-gray-100 rounded-lg'>
        <b>Por aquí verás información importante más adelante</b>
      </div>
      <div className='border border-gray-100 p-6 py-14 bg-gradient-to-b from-primary-200 to-gray-100 rounded-lg'>
        <b>Para comenzar...</b>
        <div>
          ...vincula tu cuenta de MercadoLibre con Atibo
          <button
            type='button'
            className='text-white bg-primary-700 hover:bg-primary-500 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2 mt-5'
            onClick={authorizeMercadoLibre}
          >
            Vincular tienda de ML
          </button>
        </div>
      </div>
    </div>
  );
};

const DashboardSeller = ({ userInfoData }: any) => {
  return (
    <div className='p-10 grid grid-cols-2 gap-2'>
      <div className='border border-gray-100 p-6 py-14 bg-gradient-to-b from-primary-200 to-gray-100 rounded-lg'>
        <b>Ingresaste como vendedor de la tienda de:</b>
        <div className='pl-4'>{userInfoData.lastActiveStore.emailAdmin}</div>
      </div>
      <div className='border border-gray-100 p-6 py-14 bg-gradient-to-b from-primary-200 to-gray-100 rounded-lg'>
        <b>En esta tienda tus permisos son:</b>
        <ul>
          {userInfoData.lastActiveStore.permissions &&
            userInfoData.lastActiveStore.permissions.map(
              (perm: string, idx: number) => {
                return (
                  <li className='pl-4' key={`li-p-${idx}`}>
                    {perm}
                  </li>
                );
              }
            )}
        </ul>
      </div>
    </div>
  );
};

const DashboardAdmin = ({ userInfoData }: any) => {
  return (
    <div className='p-10 grid grid-cols-2 gap-2'>
      <div className='border border-gray-100 p-6 py-14 bg-gradient-to-b from-primary-200 to-gray-100 rounded-lg'>
        <b>Ingresaste como administrador de tu tienda:</b>
        <div className='pl-4'>ID: {userInfoData.storeId}</div>
      </div>
      <div className='border border-gray-100 p-6 py-14 bg-gradient-to-b from-primary-200 to-gray-100 rounded-lg'>
        <b>En esta tienda tus vendedores son:</b>
        <ul>
          {userInfoData.usersInStore &&
            userInfoData.usersInStore.map((seller: any, idx: number) => {
              return (
                <li className='pl-4' key={`li-p-${idx}`}>
                  {seller.email}
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

const AuthorizeMercadoLibreButton = ({ userInfoData }: any) => {
  const [createMLRedirectUrl] = usersApi.useCreateMLRedirectUrlMutation();

  const authorizeMercadoLibre = async () => {
    const { url } = await createMLRedirectUrl(userInfoData).unwrap();
    window.location.href = url;
  }

  return (
    <div className="flex flex-col items-center justify-center h-full">
      <div className="text-center p-8 max-w-lg bg-white rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold text-primary-700 mb-4">Conecta tu cuenta de MercadoLibre</h2>
        <p className="text-gray-600 mb-6">Para utilizar todas las funcionalidades de Atibo, necesitas conectar tu cuenta de MercadoLibre</p>
        <button
          type="button"
          className="text-white bg-primary-700 hover:bg-primary-500 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm px-8 py-3 text-center"
          onClick={authorizeMercadoLibre}
        >
          Vincular tienda de MercadoLibre
        </button>
      </div>
    </div>
  );
};

const HomePage = ({ userInfoData }: any) => {
  const [progress, setProgress] = useState(0);
  const [total, setTotal] = useState(0);

  useGetStoreSyncProgressSubscription((data) => {
    setProgress(data.progress);
    setTotal(data.total);
  });

  const isAuthorizedWithMercadoLibre = Boolean(userInfoData?.storeId);

  if (!isAuthorizedWithMercadoLibre) {
    return <AuthorizeMercadoLibreButton userInfoData={userInfoData} />;
  }

  return <div>
    <div className='pt-10'>
      <CatalogAndMarketplaceEvolution />
    </div>
    <div className="pt-10 flex gap-4">
      <div className="flex-grow">
        <SalesEvolution />
      </div>
      <div className="flex-none">
        <ItemsInfractionsChart />
      </div>
    </div>
  </div>
}

export default HomePage;

